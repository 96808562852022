/*eslint-disable*/
import React,{  useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";


import AgeVerification from "components/AgeVerification";
import { useHistory } from "react-router-dom";
export default function WelcomeBanner() {



  const [showModal, setShowModal] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['age-verified']);
  const [underAge, setUnderAge] = useState(false)
  let history = useHistory();


    useEffect(() => {
      console.log(cookies['age-verified'])
      if (cookies['age-verified'] == true) 
      {
        console.log("verified");
      
      }
      else{
        console.log("!verified");
      }  
  }, [cookies])

 
  
  

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className="header relative  items-center flex flex-wrap  max-h-860-px stockBannerBGCol">                                
    <div className="container mx-auto justify-center flex-row items-center flex w-full" >      
      
        {/* <div className="w-full flex-row lg:p-5 px-2 text-center md:text-center">       */}
        <div className="flex flex-col md:flex-row items-center  py-2">                      

            <div className="flex flex-row">
              <div className="px-2 py-2 items-center flex">                  
            
              
                  <span className="welcomeBannerTextCol" style={{fontSize: "24px",fontFamily: "Poppins, arial, sans-serif"}}>
                    FRESH CANNABIS CO.
                  </span>
              </div>
            </div>
            

            {/* <div className="px-2 flex items-center flex justify-center">
                <span className="font-market">
                    <a href="https://1cminc.com/" target="_blank">Learn More &gt;</a>
                </span>
            </div> */}
                        
        </div>
      
    </div>                      
  </section>
  );
}
