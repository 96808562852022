import React, { Component,useState,useEffect  } from 'react'
import ReactDOM from 'react-dom'
import IframeResizer from 'iframe-resizer-react'
import Footer from './Footers/Footer';


export default function IFrameWrapper({location}) {
 

const resetFrame = () =>{
    //remove buttons
    
    var elms = document.querySelectorAll("[id='dutchie--skip-to-menu']"); 
    console.log(elms.length," random buttons");
    for(var i = 0; i < elms.length; i++) 
    {
        //elms[i].style.display='none'; // <-- whatever you need to do here.
        elms[i].parentNode.removeChild(elms[i]);
    }

    
    var elms2 = document.querySelectorAll("[id='dutchie--embed__iframe']"); 
    console.log(elms2.length," iframes");

    for(var i = 0; i < elms2.length; i++) 
    {
        elms2[i].style.display='none'; // <-- whatever you need to do here.
        elms2[i].parentNode.removeChild(elms2[i]);
    }
    var elms3 = document.querySelectorAll("[id='dutchie--embed__styles']"); 
    console.log(elms3.length," iframes");

    for(var i = 0; i < elms3.length; i++) 
    {
        elms3[i].style.display='none'; // <-- whatever you need to do here.
        elms3[i].parentNode.removeChild(elms3[i]);
    }
}


 useEffect(() => {

    if(location)
    {       
        resetFrame();


        //check if a page is already loaded
        // let check1 = document.getElementById("dutchie--skip-to-menu");
        //  console.log(check1.innerHTML)




        //get the embed script location
        let menuDOM = document.getElementById("can_menu");        
        //clear the old menu
        //menuDOM.removeChild(temp);
        // console.log("removed")

        //regen a new menu container
        // const scriptContainer = document.createElement("div");
        // scriptContainer.setAttribute("id","menus")

        
        const script = document.createElement("script");
        script.src = location.menuId;
        script.async = true;
        //scriptContainer.appendChild(script);
        menuDOM.appendChild(script);   
        console.log("re added")
 
               
    }
        
 }, [location])
 
        
    return (
        <div id = "can_menu" style={{minHeight:"100vh"}}>
            {/* <div id = "menus"> */}
                <script id="dutchie--embed__script" src="https://dutchie.com/api/v2/embedded-menu/624244440abb5d3a9260bb44.js"/>
                {/* <script id="dutchie--embed__script" src={location}></script> */}
                

            {/* </div> */}
            {/* <script id="dutchie--embed__script" src="https://dutchie.com/api/v2/embedded-menu/624244440abb5d3a9260bb44.js"></script> */}
        </div>
        
    );

}