import React, {  useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function Footer() {
  const [cookies, setCookie, removeCookie] = useCookies(['age-verified']);
  const [underAge, setUnderAge] = useState(false)
  const history = useHistory();
  const analytics = getAnalytics();




  useEffect(() => {
    console.log(cookies['age-verified'])
    if (cookies['age-verified'] == "true") 
    {
      console.log("verified IN NAV BAR");
      setUnderAge(false)    
    }
    else{
      console.log("!verified IN NAV BAR",cookies['age-verified']);
      //setShowModal(true);
      setUnderAge(true)
    }  
}, [cookies])

useEffect(() => {
  window.scrollTo(0, 0);
}, [])

const routeChange = (pathName) =>{ 
  console.log("routeChanged called ");
  if(!underAge)
  {
    logEvent(analytics, 'route_change', { "path": pathName});

    if(pathName=="/membership")
    {
      let membershipURL = "https://join.mywallet.deals/?id=c437257f-71b2-45a7-9190-3f91cf78de8a&m_location=/#/login"
      window.open(membershipURL, '_blank', 'noopener,noreferrer');
    }
    else if(pathName === "/giftcards"){
      let giftCardURL = "https://card.birchmountnetwork.com/store/tcann"
      window.open(giftCardURL, '_blank', 'noopener,noreferrer');
    }
    else if(pathName=="/careers")
    {
      
      let careersURL = "https://docs.google.com/forms/d/e/1FAIpQLSfeqmotU-7Ko8SFYVzBHIp4JgnF3APcWB2yKpi_LpiBHzj2zA/viewform"
      window.open(careersURL, '_blank', 'noopener,noreferrer');
    }    
    else
    {
      window.scrollTo(0, 0);

      history.push(pathName);
    }
       
    






  }
  else{
    console.log("!verified IN NAV BAR",cookies['age-verified']);
  }
}

  return (
    <>
      <div id="footer1" className="bottom-auto relative bg-green">          
          
          <div className="flex container mx-auto flex-col md:flex-row">                       
            <div className="py-6 text-white w-full">
              <p className="footerSubText">
                Copyright © 2022 Fresh Cannabis Co - All Rights Reserved.
              </p>            
            </div>
          </div>          
      </div>
    </>
  );
}
