import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts


// views without layouts

//import ContactUs from "views/ContactUs.js";
import Index from "views/Index.js";
import AgeVerification from "components/AgeVerification";
//import Stores from "views/Stores";
//import Order from "views/Order";
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyD0Jo6kPtPpnncm8bDLZZk5tKGK8TACpiI",

  authDomain: "costcannabis-4e45b.firebaseapp.com",

  projectId: "costcannabis-4e45b",

  storageBucket: "costcannabis-4e45b.appspot.com",

  messagingSenderId: "447189356606",

  appId: "1:447189356606:web:7f2ac98c6455d5d635816f",

  measurementId: "G-EEG3QC5LGE"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

ReactDOM.render(
  <BrowserRouter>

    <Switch>            
      {/* add routes without layouts */}
      
      <Route path="/" exact component={Index} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
